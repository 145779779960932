import React, { useContext, useEffect, useState } from 'react';
import './ClientFilter.css';
import { useLocation } from 'react-router-dom';
import Slider from '@mui/material/Slider';
import { useMediaQuery } from 'react-responsive';
import { Context } from '../../../Context';
import { categories, colors, skin } from '../../../pages/Category/CategoryInfo';

export default function ClientFilter() {
  const { selectedItems, setSelectedItems, setSaveLocation, setActiveWindow, language } =
    useContext(Context);

  const location = useLocation();

  const isMobile = useMediaQuery({ maxWidth: 768 });

  // показываем в телефоне фильтр с коллекциями
  const [mobileCollection, setMobileCollection] = useState(false);

  useEffect(() => {
    if (
      location.pathname === '/all-products' ||
      location.pathname === '/en/all-products' ||
      location.pathname === '/pl/all-products'
    ) {
      setMobileCollection(true);
    } else if (
      location.pathname === '/new-collection' ||
      location.pathname === '/en/new-collection' ||
      location.pathname === '/pl/new-collection' ||
      location.pathname === '/winter-essentials' ||
      location.pathname === '/en/winter-essentials' ||
      location.pathname === '/pl/winter-essentials' ||
      location.pathname === '/jackets-23-24' ||
      location.pathname === '/en/jackets-23-24' ||
      location.pathname === '/pl/jackets-23-24'
    ) {
      if (isMobile) {
        setMobileCollection(true);
      }
    }
  }, [location, isMobile]);

  const [seeAllColor, setSeeAllColor] = useState(4);
  const [seeAllleather, setSeeAllleather] = useState(4);
  const [seeAllСategories, setSeeAllСategories] = useState(4);

  // Функция для обработки клика по чекбоксу по цвету
  const handleCheckboxClick = (item, type) => {
    setSelectedItems((prevState) => {
      const updatedItems = { ...prevState };
      if (!updatedItems[type]) {
        updatedItems[type] = [item];
      } else {
        const index = updatedItems[type].indexOf(item);
        if (index === -1) {
          updatedItems[type].push(item);
        } else {
          updatedItems[type].splice(index, 1);
        }
      }
      return updatedItems;
    });
    setSaveLocation(location.pathname);
  };

  // записываем фильтр по цене
  const handleChange = (event, number) => {
    setSelectedItems((prevState) => {
      const updatedItems = { ...prevState, number };
      return updatedItems;
    });
  };

  const texts = {
    ukr: {
      title: 'фільтр',
      reset: 'скинути всі',
      categories: 'категорії',
      seeAll: 'переглянути всі',
      color: 'колір',
      type: 'тип шкіри / хутра',
      price: 'ціна',
      currency: '₴',
      maxNumber: '80000',
      enter: 'застосувати',
    },
    eng: {
      title: 'filters',
      reset: 'reser all',
      categories: 'categories',
      seeAll: 'see all',
      color: 'colors',
      type: 'skin/fur type',
      price: 'price',
      currency: '€',
      maxNumber: '2000',
      enter: 'to apply',
    },
    pl: {
      title: 'Filtry',
      reset: 'Resetuj wszystko',
      categories: 'Kategorie',
      seeAll: 'Zobacz wszystko',
      color: 'Kolory',
      type: 'typ skóry/futra',
      price: 'Cena',
      currency: '€',
      maxNumber: '2000',
      enter: 'aplikować',
    },
  };

  const selectedLanguageData = texts[language.toLowerCase()];

  return (
    <div className="clientFilter">
      <div className="clientFilter__title">{selectedLanguageData.title}</div>
      <button
        type="button"
        onClick={() => setSelectedItems({ color: [], skin: [], categories: [], number: 0 })}
        className="clientFilter__reset"
      >
        {selectedLanguageData.reset}
      </button>

      {mobileCollection && (
        <div className="clientFilter__category">{selectedLanguageData.categories}</div>
      )}

      {mobileCollection &&
        language &&
        categories[language].slice(0, seeAllСategories).map((item, index) => (
          <div
            onClick={() => handleCheckboxClick(item.value, 'category')}
            key={index}
            className="clientFilter__item"
          >
            <button
              type="button"
              className={`clientFilter__item-check ${
                selectedItems.category && selectedItems.category.includes(item.value)
                  ? 'clientFilter__item-check-active'
                  : ''
              }`}
            >
              <img className="img-contain" src="/img/main/check.svg" alt="check" />
            </button>

            <div className="clientFilter__item-text">{item.label}</div>
          </div>
        ))}

      {mobileCollection && seeAllСategories !== 11 ? (
        <button type="button" onClick={() => setSeeAllСategories(11)} className="clientFilter__see">
          {selectedLanguageData.seeAll}
        </button>
      ) : (
        false
      )}

      <div className="clientFilter__category">{selectedLanguageData.color}</div>

      {language &&
        colors[language].slice(0, seeAllColor).map((item, index) => (
          <div
            onClick={() => handleCheckboxClick(item.value, 'color')}
            key={index}
            className="clientFilter__item"
          >
            <button
              type="button"
              className={`clientFilter__item-check ${
                selectedItems.color && selectedItems.color.includes(item.value)
                  ? 'clientFilter__item-check-active'
                  : ''
              }`}
            >
              <img className="img-contain" src="/img/main/check.svg" alt="check" />
            </button>

            <div className={`clientFilter__item-color ${item.value}`} />
            <div className="clientFilter__item-text">{item.label}</div>
          </div>
        ))}

      {seeAllColor !== 17 ? (
        <button type="button" onClick={() => setSeeAllColor(17)} className="clientFilter__see">
          {selectedLanguageData.seeAll}
        </button>
      ) : (
        false
      )}

      <div className="clientFilter__category">{selectedLanguageData.type}</div>

      {language &&
        skin[language].slice(0, seeAllleather).map((item, index) => (
          <div
            onClick={() => handleCheckboxClick(item.value, 'skin')}
            key={index}
            className="clientFilter__item"
          >
            <button
              type="button"
              className={`clientFilter__item-check ${
                selectedItems.skin && selectedItems.skin.includes(item.value)
                  ? 'clientFilter__item-check-active'
                  : ''
              }`}
            >
              <img className="img-contain" src="/img/main/check.svg" alt="check" />
            </button>
            <div className="clientFilter__item-text">{item.label}</div>
          </div>
        ))}

      {seeAllleather !== 6 ? (
        <button type="button" onClick={() => setSeeAllleather(9)} className="clientFilter__see">
          {selectedLanguageData.seeAll}
        </button>
      ) : (
        false
      )}
      <div className="clientFilter__category">{selectedLanguageData.price}</div>

      <Slider
        value={selectedItems.number}
        onChange={handleChange}
        min={0}
        max={selectedLanguageData.maxNumber}
        sx={{
          color: 'black',
          '& .MuiSlider-thumb': {
            display: 'none',
          },
          '& .MuiSlider-rail': {
            color: '#bfbfbf',
          },
        }}
      />

      <div className="clientFilter__slider">
        <div className="clientFilter__slider-number">{selectedLanguageData.currency} 0</div>
        {selectedItems.number > 0 && (
          <div className="clientFilter__slider-number">
            {selectedLanguageData.currency} {selectedItems.number}
          </div>
        )}
      </div>

      {isMobile && (
        <button
          type="button"
          onClick={() => setActiveWindow(false)}
          className="basket__cost-button clientFilter-md"
        >
          {selectedLanguageData.enter}
        </button>
      )}
    </div>
  );
}
