import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { scroller } from 'react-scroll';
import { db } from '../../firebase';
import { setDoc, doc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import { Context } from '../../Context';
import { texts } from './PaymentInfo';
import Loader from '../../components/Loader/Loader';
import { colorsLanguage } from '../Admin/Setting/Others';
import './Payment.css';

export default function Payment() {
  const { data, setSum, sum, busketNumber, language } = useContext(Context);

  const [step, setStep] = useState(1);
  const [itemList, setItemList] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    secondName: '',
    phone: '',
    email: '',
    instagram: '',
    deliveryMethod: '',
    showroom: '',
    country: '',
    typeDelivery: '',
    adressDelivery: {
      country: '',
      city: '',
      street: '',
      building: '',
      appartment: '',
      index: '',
    },
    postDepartament: {
      country: '',
      city: '',
      departamentNumber: '',
      index: '',
    },
    paymentMethod: '',
  });
  const [formFilled1, setFormFilled1] = useState(false);
  const [formFilled2, setFormFilled2] = useState(false);
  const [formFilled3, setFormFilled3] = useState(false);
  const [placeholderState, setPlaceholderState] = useState(false);
  const [placeholdeFlag, setPlaceholderFlag] = useState(false);
  const [placeholdeFlag2, setPlaceholderFlag2] = useState(false);
  const [placeholdeFlag3, setPlaceholderFlag3] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [liqpayData, setLiqpayData] = useState({});
  const [formHTML, setFormHTML] = useState(false);

  const [loader, setLoader] = useState(false);
  const [telegramFlag, setTelegramFlag] = useState(true);
  const [currentDate, setCurrentDate] = useState('');
  const [stringDate, setStringDate] = useState('');
  const [srmDate, setSrmDate] = useState('');

  const navigate = useNavigate();

  const selectedLanguageData = texts[language.toLowerCase()];
  const selectedLanguageColors = colorsLanguage[language.toLowerCase()];

  // запис дати в айді

  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();

    const pad = (number) => (number < 10 ? `0${number}` : number);

    const month = pad(now.getMonth() + 1);
    const day = pad(now.getDate());
    const hours = pad(now.getHours());
    const minutes = pad(now.getMinutes());
    const seconds = pad(now.getSeconds());

    setCurrentDate(`${month}_${day}_${hours}:${minutes}:${seconds}`);
    setStringDate(`${year}${month}${day}`);
    setSrmDate(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
  }, []);

  // cкрол
  const scrollToSection1 = () => {
    scroller.scrollTo('section1', {
      offset: -50,
    });
  };

  // оновляємо форм3 в випадку зміни суми
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      paymentMethod: '',
    }));
    setCheckbox(false);
    setTelegramFlag(true);
  }, [sum]);

  // запис форм дати
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parentProperty, nestedProperty] = name.split('.');
      setFormData({
        ...formData,
        [parentProperty]: {
          ...formData[parentProperty],
          [nestedProperty]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // перевірка станів
  const checkForm1Filled = useCallback(() => {
    const isFilled =
      formData.firstName.trim().length > 0 &&
      formData.secondName.trim().length > 0 &&
      formData.phone.trim().length > 0 &&
      formData.firstName.length > 2 &&
      formData.secondName.length > 2 &&
      formData.phone.length > 5;
    setFormFilled1(isFilled);
    setPlaceholderState(!isFilled && placeholdeFlag);
  }, [formData, placeholdeFlag]);

  const checkForm2Filled = useCallback(() => {
    if (
      formData.country &&
      formData.adressDelivery.city &&
      formData.adressDelivery.street &&
      formData.adressDelivery.building &&
      formData.adressDelivery.appartment
    ) {
      setFormFilled2(true);
      setPlaceholderState(false);
    } else if (formData.showroom) {
      setFormFilled2(true);
      setPlaceholderState(false);
    } else if (formData.country && formData.postDepartament.city && formData.postDepartament.departamentNumber) {
      setFormFilled2(true);
      // setPlaceholderState(false);
    } else {
      setFormFilled2(false);
      if (placeholdeFlag2) {
        setPlaceholderState(true);
      }
    }
    // eslint-disable-next-line
  }, [formData]);

  const checkForm3Filled = useCallback(() => {
    if (formData.paymentMethod && checkbox) {
      setFormFilled3(true);
      if (telegramFlag && formData.paymentMethod === 'Payment online') {
        setTelegramFlag(false);
        // handleSubmit();
      }
      setPlaceholderState(false);
    } else {
      setFormFilled3(false);
      if (placeholdeFlag3) {
        setPlaceholderState(true);
      }
    }
    // eslint-disable-next-line
  }, [formData, checkbox]);

  useEffect(() => {
    checkForm1Filled();
    checkForm2Filled();
    checkForm3Filled();
  }, [formData, checkForm2Filled, checkForm1Filled, checkForm3Filled]);

  // отримання данних
  useEffect(() => {
    const localId = localStorage.getItem('gots');
    if (busketNumber.length < 1) {
      navigate(selectedLanguageData.navigate);
    }
    if (data && localId) {
      const idArray = JSON.parse(localId);
      const countMap = {};
      idArray.forEach((id) => {
        countMap[id] = (countMap[id] || 0) + 1;
      });
      const updatedProducts = data
        .filter((product) => countMap[product.id])
        .map((product) => ({
          ...product,
          quantity: countMap[product.id],
        }));
      setItemList(updatedProducts);
      const total = updatedProducts.reduce((acc, item) => {
        const price = parseInt(
          language === 'ukr'
            ? item.priceUaSale && item.priceUaSale !== ''
              ? item.priceUaSale
              : item.priceUa
            : item.priceEnSale && item.priceUaSale !== ''
            ? item.priceEnSale
            : item.priceEn,
        );
        return acc + price * item.quantity;
      }, 0);
      setSum(total);

      // данные для ликпея
      // Преобразуем массив имен товаров в строку, разделяя их запятыми
      let productName = '';
      let productCount = 0;

      updatedProducts.forEach((item, index) => {
        const name = language === 'ukr' ? item.titleUa : language === 'eng' ? item.titleEn : item.titlePl;
        if (index > 0) {
          productName += ', ';
        }
        productName += name;
        productCount += item.quantity;
      });

      setLiqpayData({
        result_url: selectedLanguageData.linkBack,
        amount: total.toString(),
        currency: language === 'ukr' ? 'UAH' : 'EUR',
        orderReference: currentDate,
        language: language === 'ukr' ? 'ua' : 'en',
        server_url: 'https://backend-car-couture.onrender.com/wfp-callback-gots',
        orderDate: stringDate,
        productPrice: total.toString(),
        productName,
        productCount: productCount.toString(),
      });
    }
    // eslint-disable-next-line
  }, [data, busketNumber, sum, language, currentDate]);

  const liqpay = async (data) => {
    try {
      setLoader(true);
      const response = await axios.post('https://backend-car-couture.onrender.com/wayforpay-gots', data);
      setFormHTML(response.data);
      setLoader(false);
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  // сабміт форми
  const handleSubmit = async () => {
    const colorTranslations = {
      white: 'білий',
      black: 'чорний',
      grey: 'сірий',
      blue: 'голубий',
      pink: 'рожевий',
      burgundy: 'бордовий',
      khaki: 'хакі',
      brown: 'коричневий',
      beige: 'бежевий',
      ginger: 'рудий',
    };
    const totalPriceSuffix = language === 'ukr' ? 'грн' : 'eur';
    const productList = itemList.map((item, index) => {
      const itemPrice = parseInt(
        language === 'ukr'
          ? item.priceUaSale && item.priceUaSale !== ''
            ? item.priceUaSale
            : item.priceUa
          : item.priceEnSale && item.priceUaSale !== ''
          ? item.priceEnSale
          : item.priceEn,
      );
      const translatedColor = colorTranslations[item.color] || item.color;
      return `${index + 1}й. ${item.titleUa}, кількість: ${
        item.quantity
      }, колір: ${translatedColor}, ціна: ${itemPrice} ${totalPriceSuffix}`;
    });
    const productNames = productList.join('\n');

    const telegramStatusPayment = formData.paymentMethod === 'PAY UPON RECEIPT' ? '' : 'непідтвержено';

    const totalOrderText = `${sum} ${totalPriceSuffix}`;

    const translateData = {
      'Номер замовлення': currentDate,
      Сума: totalOrderText,
      'Cтатус оплати': telegramStatusPayment,
      '-=-=-=-=-=-': '=-=-=-=-=-=',
      "Ім'я": formData.firstName,
      Прізвище: formData.secondName,
      Телефон: formData.phone,
      'Електронна пошта': formData.email,
      Instagram: formData.instagram === '' ? 'не вказано' : formData.instagram,
      '=-=-=-=-=-=': '-=-=-=-=-=-',
      Товар: productNames,
      '-===-=-=-=-': '=-=-=-=-=-=',
      'Спосіб отримання':
        formData.deliveryMethod === 'storePickup' ? 'Самовивіз з магазину' : formData.deliveryMethod === 'homeDelivery' ? 'Доставка' : '',
      Шоурум: formData.showroom || '',
      Країна: formData.country === 'ukraine' ? 'Україна' : formData.country === 'otherCountry' ? 'Інші країни' : '',
      'Тип доставки':
        formData.typeDelivery === 'adress delivery'
          ? 'Адресна доставка'
          : formData.typeDelivery === 'delivery to post department'
          ? 'Доставка у відділення пошти'
          : '',
      'Адреса доставки':
        formData.typeDelivery === 'adress delivery'
          ? {
              Країна: formData.adressDelivery.country || '',
              Місто: formData.adressDelivery.city || '',
              Вулиця: formData.adressDelivery.street || '',
              Будинок: formData.adressDelivery.building || '',
              Квартира: formData.adressDelivery.appartment || '',
              Індекс: formData.adressDelivery.index || '',
            }
          : '',
      'Поштове відділення':
        formData.typeDelivery === 'delivery to post department'
          ? {
              Країна: formData.postDepartament.country || '',
              Місто: formData.postDepartament.city || '',
              Індекс: formData.postDepartament.index || '',
              'Номер поштового відділення': formData.postDepartament.departamentNumber || '',
            }
          : '',
      'Спосіб оплати':
        formData.paymentMethod === 'PAY UPON RECEIPT'
          ? 'Оплата готівкою в магазині'
          : formData.paymentMethod === 'Payment online'
          ? 'Оплата онлайн'
          : '',
    };

    const ordersApiData = {
      personalData: formData,
      productData: itemList,
      isPaymentAndEmailCompleted: false,
      language: language,
    };

    try {
      await Promise.all([
        //  addProductCrm(); //срм
        setDoc(doc(db, 'orders', currentDate), ordersApiData),
        axios.post('https://backend-car-couture.onrender.com/gots', translateData),
      ]);
    } catch (error) {
      console.error('Error handling request:', error);
    }
  };

  const sendEmail = async () => {
    const emailData = {
      personalData: formData,
      productData: itemList,
      language: language,
    };

    try {
      await axios.post('https://backend-car-couture.onrender.com/send-email', emailData);
      console.log('Email відправлено успішно');
    } catch (error) {
      console.log('Помилка при відправці email:', error.message);
    }
  };

  // ------------------------------crm ------------------------------

  const addProductCrm = async () => {
    setLoader(true);
    const apiURL = 'https://backend-car-couture.onrender.com/crm-add-order';
    const { firstName, secondName, email, phone } = formData;

    let crmProductList = [];

    itemList.forEach((item) => {
      const itemPrice = parseInt(
        language === 'ukr'
          ? item.priceUaSale && item.priceUaSale !== ''
            ? item.priceUaSale
            : item.priceUa
          : item.priceEnSale && item.priceEnSale !== ''
          ? item.priceEnSale
          : item.priceEn,
      );

      crmProductList.push({
        sku: item.id,
        price: itemPrice,
        quantity: item.quantity,
        name: item.titleUa,
        picture: item.img[0],
        properties: [
          {
            name: 'Color',
            value: item.color,
          },
        ],
      });
    });

    const source_id = language === 'ukr' ? 2 : 3;

    const data = {
      source_id: source_id,
      source_uuid: currentDate,
      ordered_at: srmDate,
      buyer: {
        full_name: firstName + ' ' + secondName,
        email: email,
        phone: phone,
      },
      products: crmProductList,
    };
    try {
      await axios.post(apiURL, data);
    } catch (error) {
      console.error('Error sending data:', error);
    } finally {
      setLoader(false);
    }
  };

  const form1inputFields = [
    { name: 'firstName', placeholder: selectedLanguageData.form1.text1 },
    { name: 'secondName', placeholder: selectedLanguageData.form1.text2 },
  ];
  const form1inputFields2 = [
    {
      type: 'email',
      name: 'email',
      placeholder: selectedLanguageData.form1.text3,
    },
    {
      type: 'phone',
      name: 'phone',
      placeholder: selectedLanguageData.form1.text4,
    },
  ];

  const form2fields1 = [
    { name: 'city', placeholder: selectedLanguageData.form2.input1 },
    { name: 'street', placeholder: selectedLanguageData.form2.input2 },
    { name: 'building', placeholder: selectedLanguageData.form2.input3 },
    { name: 'appartment', placeholder: selectedLanguageData.form2.input4 },
  ];
  const form2fields2 = [
    { name: 'city', placeholder: selectedLanguageData.form2.input5 },
    {
      name: 'departamentNumber',
      placeholder: selectedLanguageData.form2.input6,
    },
  ];

  const handleChangeCheckbox = () => {
    if (checkbox) {
      setCheckbox(false);
    } else {
      setCheckbox(true);
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div name="section1" className="payment">
        <div className="payment__wrap">
          <div className="payment__form-wrap">
            <div className="payment__form-personal">
              <h2 className="payment__form-title" onClick={() => setStep(1)}>
                {selectedLanguageData.form1.title}
              </h2>
              {step === 1 ? (
                <form
                  className="payment__form-group-input"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (formFilled1) {
                      setStep(2);
                    } else {
                      setPlaceholderState(true);
                      setPlaceholderFlag(true);
                    }
                    scrollToSection1();
                  }}
                >
                  {placeholderState ? <p className="payment__form-warning">{selectedLanguageData.form1.checkForm}</p> : false}
                  <div className="payment__form-personal-name-wrap">
                    {form1inputFields.map((field, index) => (
                      <input
                        key={index}
                        className={`payment__form-input ${!placeholderState ? '' : 'payment__form-input--empty'}`}
                        type="text"
                        name={field.name}
                        placeholder={field.placeholder}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required
                      />
                    ))}
                  </div>
                  <div className="payment__form-personal-info-wrap">
                    {form1inputFields2.map((field, index) => (
                      <input
                        key={index}
                        className={`payment__form-input ${field.type === 'phone' && placeholderState ? 'payment__form-input--empty' : ''}`}
                        type={field.type}
                        name={field.name}
                        placeholder={field.placeholder}
                        value={formData[field.name]}
                        onChange={handleChange}
                        required={field.type === 'email'}
                      />
                    ))}
                  </div>
                  <input
                    className="payment__form-input payment__form-input-solo"
                    type="text"
                    name="instagram"
                    placeholder="Instagram"
                    value={formData.instagram}
                    onChange={handleChange}
                  />
                  <button
                    type="submit"
                    // onClick={}
                    className={formFilled1 ? 'payment__form-button' : 'payment__form-button payment__form-button--disable'}
                  >
                    {selectedLanguageData.form1.button}
                  </button>
                </form>
              ) : (
                false
              )}
            </div>
            <form className="payment__form-delivery">
              <h2
                className="payment__form-title"
                onClick={() => {
                  if (formFilled1) {
                    setStep(2);
                  }
                }}
              >
                {selectedLanguageData.form2.title}
              </h2>
              {step === 2 ? (
                <div className="payment__form-group-input">
                  {placeholderState ? <p className="payment__form-warning">{selectedLanguageData.form1.checkForm}</p> : false}
                  <div className="payment__form-box-couple-label-radio">
                    <label
                      className={`payment__form-label-radio ${
                        formData.deliveryMethod === 'storePickup' ? 'payment__form-label-radio-active' : ''
                      }`}
                    >
                      {selectedLanguageData.form2.text1}
                      {formData.deliveryMethod === 'storePickup' ? (
                        <div className="radioBox">
                          <div className="radioBox-active" />
                        </div>
                      ) : (
                        <div className="radioBox-disActive" />
                      )}
                      <input
                        className="payment__form-radio"
                        type="radio"
                        name="deliveryMethod"
                        value="storePickup"
                        checked={formData.deliveryMethod === 'storePickup'}
                        onChange={(e) => {
                          handleChange(e);
                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            country: '',
                            typeDelivery: '',
                          }));
                        }}
                      />
                    </label>
                    <label
                      className={`payment__form-label-radio ${
                        formData.deliveryMethod === 'homeDelivery' ? 'payment__form-label-radio-active' : ''
                      }`}
                    >
                      {selectedLanguageData.form2.text2}
                      {formData.deliveryMethod === 'homeDelivery' ? (
                        <div className="radioBox">
                          <div className="radioBox-active" />
                        </div>
                      ) : (
                        <div className="radioBox-disActive" />
                      )}
                      <input
                        className="payment__form-radio"
                        type="radio"
                        name="deliveryMethod"
                        value="homeDelivery"
                        checked={formData.deliveryMethod === 'homeDelivery'}
                        onChange={(e) => {
                          handleChange(e);

                          setFormData((prevFormData) => ({
                            ...prevFormData,
                            showroom: '',
                          }));
                        }}
                      />
                    </label>
                  </div>
                  {formData.deliveryMethod === 'storePickup' ? (
                    <div className="payment__form-box-couple-label-radio">
                      <label
                        className={`payment__form-label-radio ${formData.showroom === 'kyiv' ? 'payment__form-label-radio-active' : ''}`}
                      >
                        {selectedLanguageData.form2.text3.n1}
                        <br />
                        {selectedLanguageData.form2.text3.n2}
                        <br />
                        {selectedLanguageData.form2.text3.n3}
                        {formData.showroom === 'kyiv' ? (
                          <div className="radioBox">
                            <div className="radioBox-active" />
                          </div>
                        ) : (
                          <div className="radioBox-disActive" />
                        )}
                        <input
                          className="payment__form-radio"
                          type="radio"
                          name="showroom"
                          value="kyiv"
                          checked={formData.showroom === 'kyiv'}
                          onChange={handleChange}
                        />
                      </label>
                      <label
                        className={`payment__form-label-radio ${formData.showroom === 'warsaw' ? 'payment__form-label-radio-active' : ''}`}
                      >
                        {selectedLanguageData.form2.text4.n1}
                        <br />
                        {selectedLanguageData.form2.text4.n2}
                        <br />
                        {selectedLanguageData.form2.text4.n3}
                        {formData.showroom === 'warsaw' ? (
                          <div className="radioBox">
                            <div className="radioBox-active" />
                          </div>
                        ) : (
                          <div className="radioBox-disActive" />
                        )}
                        <input
                          className="payment__form-radio"
                          type="radio"
                          name="showroom"
                          value="warsaw"
                          checked={formData.showroom === 'warsaw'}
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                  ) : (
                    false
                  )}
                  {formData.deliveryMethod === 'homeDelivery' ? (
                    <div className="payment__form-box-couple-label-radio">
                      <label
                        className={`payment__form-label-radio ${formData.country === 'ukraine' ? 'payment__form-label-radio-active' : ''}`}
                      >
                        {selectedLanguageData.form2.text5.n1}
                        <br />
                        {selectedLanguageData.form2.text5.n2}
                        {formData.country === 'ukraine' ? (
                          <div className="radioBox">
                            <div className="radioBox-active" />
                          </div>
                        ) : (
                          <div className="radioBox-disActive" />
                        )}
                        <input
                          className="payment__form-radio"
                          type="radio"
                          name="country"
                          value="ukraine"
                          checked={formData.country === 'ukraine'}
                          onChange={handleChange}
                        />
                      </label>

                      <label
                        className={`payment__form-label-radio ${
                          formData.country === 'otherCountry' ? 'payment__form-label-radio-active' : ''
                        }`}
                      >
                        {selectedLanguageData.form2.text6.n1}
                        <br />
                        {selectedLanguageData.form2.text6.n2}
                        {formData.country === 'otherCountry' ? (
                          <div className="radioBox">
                            <div className="radioBox-active" />
                          </div>
                        ) : (
                          <div className="radioBox-disActive" />
                        )}
                        <input
                          className="payment__form-radio"
                          type="radio"
                          name="country"
                          value="otherCountry"
                          checked={formData.country === 'otherCountry'}
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                  ) : (
                    false
                  )}
                  {formData.country !== '' && formData.deliveryMethod === 'homeDelivery' ? (
                    <div className="payment__form-box-couple-label-radio">
                      <label
                        className={`payment__form-label-radio ${
                          formData.typeDelivery === 'adress delivery' ? 'payment__form-label-radio-active' : ''
                        }`}
                      >
                        {selectedLanguageData.form2.text7}
                        {formData.typeDelivery === 'adress delivery' ? (
                          <div className="radioBox">
                            <div className="radioBox-active" />
                          </div>
                        ) : (
                          <div className="radioBox-disActive" />
                        )}
                        <input
                          className="payment__form-radio"
                          type="radio"
                          name="typeDelivery"
                          value="adress delivery"
                          checked={formData.typeDelivery === 'adress delivery'}
                          onChange={handleChange}
                        />
                      </label>
                      <label
                        className={`payment__form-label-radio ${
                          formData.typeDelivery === 'delivery to post department' ? 'payment__form-label-radio-active' : ''
                        }`}
                      >
                        {selectedLanguageData.form2.text8}
                        {formData.typeDelivery === 'delivery to post department' ? (
                          <div className="radioBox">
                            <div className="radioBox-active" />
                          </div>
                        ) : (
                          <div className="radioBox-disActive" />
                        )}
                        <input
                          className="payment__form-radio"
                          type="radio"
                          name="typeDelivery"
                          value="delivery to post department"
                          checked={formData.typeDelivery === 'delivery to post department'}
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                  ) : (
                    false
                  )}
                  {formData.typeDelivery === 'adress delivery' && formData.deliveryMethod === 'homeDelivery' ? (
                    <div className="payment__form-group-label">
                      {formData.country === 'otherCountry' ? (
                        <input
                          className={`payment__form-input ${!placeholderState ? '' : 'payment__form-input--empty'}`}
                          type="text"
                          name="adressDelivery.country"
                          placeholder={selectedLanguageData.form2.input8}
                          value={formData.adressDelivery.country}
                          onChange={handleChange}
                          required
                        />
                      ) : (
                        false
                      )}
                      {form2fields1.map((field, index) => (
                        <input
                          key={index}
                          className={`payment__form-input ${!placeholderState ? '' : 'payment__form-input--empty'}`}
                          type={field.name === 'appartment' ? 'tel' : 'text'}
                          name={`adressDelivery.${field.name}`}
                          placeholder={field.placeholder}
                          value={formData.adressDelivery[field.name]}
                          onChange={handleChange}
                          required
                        />
                      ))}
                      {formData.country === 'otherCountry' ? (
                        <input
                          className={`payment__form-input ${!placeholderState ? '' : 'payment__form-input--empty'}`}
                          type="text"
                          name="adressDelivery.index"
                          placeholder={selectedLanguageData.form2.input7}
                          value={formData.adressDelivery.index}
                          onChange={handleChange}
                          required
                        />
                      ) : (
                        false
                      )}
                    </div>
                  ) : (
                    false
                  )}
                  {formData.typeDelivery === 'delivery to post department' && formData.deliveryMethod === 'homeDelivery' ? (
                    <div className="payment__form-group-label">
                      {formData.country === 'otherCountry' ? (
                        <input
                          className={`payment__form-input ${!placeholderState ? '' : 'payment__form-input--empty'}`}
                          type="text"
                          name="postDepartament.country"
                          placeholder={selectedLanguageData.form2.input8}
                          value={formData.postDepartament.country}
                          onChange={handleChange}
                          required
                        />
                      ) : (
                        false
                      )}
                      {form2fields2.map((field, index) => (
                        <input
                          key={index}
                          className={`payment__form-input ${!placeholderState ? '' : 'payment__form-input--empty'}`}
                          type="text"
                          name={`postDepartament.${field.name}`}
                          placeholder={field.placeholder}
                          value={formData.postDepartament[field.name]}
                          onChange={handleChange}
                          required
                        />
                      ))}
                      {formData.country === 'otherCountry' ? (
                        <input
                          className={`payment__form-input ${!placeholderState ? '' : 'payment__form-input--empty'}`}
                          type="text"
                          name="postDepartament.index"
                          placeholder={selectedLanguageData.form2.input7}
                          value={formData.postDepartament.index}
                          onChange={handleChange}
                          required
                        />
                      ) : (
                        false
                      )}
                    </div>
                  ) : (
                    false
                  )}

                  <p className="payment__form-box-couple-pay-info">{selectedLanguageData.payInfo}</p>

                  <button
                    type="button"
                    onClick={(e) => {
                      scrollToSection1();
                      e.preventDefault();
                      if (formFilled2) {
                        setStep(3);
                        setPlaceholderState(false);
                      } else {
                        setPlaceholderState(true);
                        setPlaceholderFlag2(true);
                      }
                    }}
                    className={formFilled2 ? 'payment__form-button' : 'payment__form-button payment__form-button--disable'}
                  >
                    {selectedLanguageData.form1.button}
                  </button>
                </div>
              ) : (
                false
              )}
            </form>
            <form className="payment__form-paymentMetod">
              <h2
                className="payment__form-title"
                onClick={() => {
                  if (formFilled1 && formFilled2) {
                    setStep(3);
                  }
                }}
              >
                {selectedLanguageData.form3.title}
              </h2>
              {step === 3 ? (
                <div className="payment__form-group-input">
                  {placeholderState ? <p className="payment__form-warning">{selectedLanguageData.form1.checkForm}</p> : false}
                  <div className="payment__form-box-couple-label-radio">
                    {formData.showroom ? (
                      <label
                        className={`payment__form-label-radio ${
                          formData.paymentMethod === 'PAY UPON RECEIPT' ? 'payment__form-label-radio-active' : ''
                        }`}
                      >
                        {selectedLanguageData.form3.text1}
                        {formData.paymentMethod === 'PAY UPON RECEIPT' ? (
                          <div className="radioBox">
                            <div className="radioBox-active" />
                          </div>
                        ) : (
                          <div className="radioBox-disActive" />
                        )}
                        <input
                          className="payment__form-radio"
                          type="radio"
                          name="paymentMethod"
                          value="PAY UPON RECEIPT"
                          checked={formData.paymentMethod === 'PAY UPON RECEIPT'}
                          onChange={handleChange}
                        />
                      </label>
                    ) : (
                      false
                    )}
                    <label
                      className={`payment__form-label-radio ${
                        formData.paymentMethod === 'Payment online' ? 'payment__form-label-radio-active' : ''
                      }`}
                      onClick={() => liqpay(liqpayData)}
                    >
                      {selectedLanguageData.form3.text2}
                      {formData.paymentMethod === 'Payment online' ? (
                        <div className="radioBox">
                          <div className="radioBox-active" />
                        </div>
                      ) : (
                        <div className="radioBox-disActive" />
                      )}
                      <input
                        className="payment__form-radio"
                        type="radio"
                        name="paymentMethod"
                        value="Payment online"
                        checked={formData.paymentMethod === 'Payment online'}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                  <div className="payment__form-paymentMetod-checkbox" onClick={handleChangeCheckbox}>
                    {checkbox ? (
                      <div className="checkbox">
                        <div className="checkbox-active" />
                      </div>
                    ) : (
                      <div className="checkbox-disActive" />
                    )}
                    <p
                      className={`payment__form-paymentMetod-checkbox-text  ${
                        !placeholderState || checkbox ? '' : 'payment__form-paymentMetod-checkbox-text-active'
                      }  `}
                    >
                      {selectedLanguageData.form3.text4}
                      <Link to={selectedLanguageData.privacyNavigate} className="payment__form-paymentMetod-checkbox-link" target="_blank">
                        {selectedLanguageData.form3.link}
                      </Link>
                      {selectedLanguageData.form3.text5}
                    </p>
                  </div>
                  {formData.paymentMethod === 'PAY UPON RECEIPT' && formFilled3 && (
                    <button
                      type="button"
                      onClick={async (e) => {
                        e.preventDefault();

                        if (formFilled3) {
                          setLoader(true);
                          handleSubmit(e); // Валідація та інші дії при відправці форми
                          await sendEmail(); // Чекаємо завершення відправки email
                          setLoader(false);
                          navigate(selectedLanguageData.navigateAfterSubmit); // Навігація після відправки email
                        } else {
                          setPlaceholderState(true);
                          setPlaceholderFlag3(true); // Виводимо помилки заповнення
                        }
                      }}
                      className={formFilled3 ? 'payment__form-button' : 'payment__form-button payment__form-button--disable'}
                    >
                      {selectedLanguageData.form3.button}
                    </button>
                  )}
                  {!formFilled3 && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        if (formFilled3) {
                          handleSubmit(e);
                        } else {
                          setPlaceholderState(true);
                          setPlaceholderFlag3(true);
                        }
                      }}
                      className={formFilled3 ? 'payment__form-button' : 'payment__form-button payment__form-button--disable'}
                    >
                      {selectedLanguageData.form3.button}
                    </button>
                  )}
                  {formData.paymentMethod !== 'PAY UPON RECEIPT' && formFilled3 && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        if (formFilled3) {
                          setLoader(true);
                          handleSubmit();
                          setTimeout(() => {
                            window.location.href = formHTML;
                            setLoader(false);
                          }, 500);
                        } else {
                          setPlaceholderState(true);
                          setPlaceholderFlag3(true);
                        }
                      }}
                      className={formFilled3 ? 'payment__form-button' : 'payment__form-button payment__form-button--disable'}
                    >
                      {selectedLanguageData.form3.button}
                    </button>
                  )}
                </div>
              ) : (
                false
              )}
            </form>
          </div>
          {itemList ? (
            <div className="payment-decktop">
              <ul className="payment__list-item-wrap">
                {itemList.map(
                  ({ priceUa, priceUaSale, priceEn, priceEnSale, color, id, size, img, titleUa, titleEn, titlePl, quantity }) => (
                    <li className="payment__list-item" key={id}>
                      <div className="payment__list-item-img-wrap">
                        <img src={img} alt={titleEn} className="payment__list-item-img" />
                      </div>
                      <div className="payment__list-info-wrap">
                        <div className="payment__list-item-title">
                          {language === 'ukr' && titleUa}
                          {language === 'eng' && titleEn}
                          {language === 'pl' && titlePl}
                        </div>
                        <div className="payment__list-item-info">
                          {color && (
                            <p className="payment__list-item-info-p">
                              {selectedLanguageData.color} {selectedLanguageColors[color]}
                            </p>
                          )}
                          <p className="payment__list-item-info-p">{selectedLanguageData.size} oversize</p>
                        </div>
                        <div className="payment__list-item-priceCount-wrap">
                          <div className="payment__list-item-price">
                            {language === 'ukr' ? (
                              <>
                                ₴{' '}
                                {priceUaSale && priceUaSale !== '' ? (
                                  <>
                                    {quantity * priceUaSale} <span className="payment__list-item-price-sale">₴{quantity * priceUa}</span>
                                  </>
                                ) : (
                                  quantity * priceUa
                                )}
                              </>
                            ) : (
                              <>
                                €{' '}
                                {priceEnSale && priceEnSale !== '' ? (
                                  <>
                                    {quantity * priceEnSale} <span className="payment__list-item-price-sale">€{quantity * priceEn}</span>
                                  </>
                                ) : (
                                  quantity * priceEn
                                )}
                              </>
                            )}
                            {selectedLanguageData.priceIcon}{' '}
                            {selectedLanguageData.priceSale && selectedLanguageData.priceSale !== '' ? (
                              <>
                                {selectedLanguageData.priceSale}{' '}
                                <span className="payment__list-item-price-sale">
                                  {selectedLanguageData.priceIcon}
                                  {selectedLanguageData.price}
                                </span>
                              </>
                            ) : (
                              selectedLanguageData.price
                            )}
                          </div>
                          <div className="payment__list-item-quantity">
                            {selectedLanguageData.qua} {quantity}
                          </div>
                        </div>
                      </div>
                    </li>
                  ),
                )}
              </ul>
              <p className="payment__list-item-total">
                {selectedLanguageData.total} {sum}
              </p>
            </div>
          ) : (
            false
          )}
        </div>
      </div>
    </>
  );
}
